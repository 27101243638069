@font-face {
  font-family: 'font-primary';
  src: url('../fonts/Prompt-Regular.eot');
  src: url('../fonts/Prompt-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Prompt-Regular.woff2') format('woff2'), url('../fonts/Prompt-Regular.woff') format('woff'), url('../fonts/Prompt-Regular.ttf') format('truetype'), url('../fonts/Prompt-Regular.svg#svgFontName') format('svg');
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: 'font-primary', Fallback, sans-serif !important;
}
.container {
  width: 100%;
  padding: 10px;
}
.header {
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
}
.header .title {
  font-weight: bold;
}
.header .sub-title {
  display: flex;
  justify-content: space-between;
}
.header .sub-title .step {
  display: flex;
}
.header .sub-title .step hr {
  width: 10px;
  margin-top: 13px;
  border: 0;
  height: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.header .sub-title .step hr.active {
  background-color: #08a7a9 !important;
  border-top: 2px solid #08a7a9;
}
.header .sub-title .step .item {
  margin: 0 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #08a7a9 solid;
  border-radius: 50%;
  background-color: white;
}
.header .sub-title .step .item.active {
  background-color: #08a7a9 !important;
  color: #fff;
}
.form-item {
  margin-bottom: 24px;
  color: #000;
}
.app-bar {
  height: 50px;
  width: 100%;
  background: linear-gradient(to right, rgba(5, 71, 108, 1), rgba(8, 167, 169, 1));
  padding: 10px;
}
.app-bar .body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  width: 100%;
  height: 100%;
}
.app-bar .body .logo {
  width: 100px;
  height: 100%;
  padding: 3px;
}
.app-bar .body .logo img {
  width: 100%;
}
.icon {
  color: #fff !important;
  font-size: 18px;
}
.terms {
  color: #08a7a9;
  margin: 0 5px;
}
.mr-5 {
  margin-right: 5px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.custom-loading .ant-modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
}
.text-center {
  text-align: center;
}
.policy {
  width: 100%;
}
.policy p {
  color: black;
}
.policy ol {
  color: black;
  margin-left: 30px;
}
.policy .content {
  text-indent: 30px;
}
.policy .detail {
  color: black;
}
.policy .detail div + div {
  margin-top: 5px;
}
.register {
  padding: 10px;
}
.location-report {
  padding: 10px;
}
.issues-report {
  padding: 10px;
}
.product-model {
  padding: 10px;
}
